import { Button } from '@arckit/ui'
import { Col } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import { Link } from 'gatsby'
import React from 'react'
// @ts-ignore
import Fade from 'react-reveal/Fade'

export const AddOnPackage = () => {
	return (
		<React.Fragment>
			<Col width="w-full" className="mb-6">
				<div className="relative">
					<div className="arc-gradient rounded-t p-3 text-center">
						<span className="text-lg font-bold text-white">
							ARC Core<sup>®</sup> Digital Support is available for select themes for Grades K–8 in English and K–5 in Spanish.{' '}
							<Link to="/themes" className="text-white underline hover:text-white">
								See available themes.
							</Link>
						</span>
					</div>
					<div className="arc-gradient-light relative w-full px-6 py-5 pb-10">
						<span className="inline-block w-full text-center text-2xl font-semibold">With each unit you will receive...</span>
						<div className="flex flex-wrap sm:flex-nowrap">
							<Fade bottom>
								<div className="mr-3 w-full sm:w-1/3">
									<img src="/images/digital-solutions/digital-framework.png" alt="digital framework" />
									<P shade="darker" size="md" className="text-center">
										Interactive Digital Framework
									</P>
								</div>
							</Fade>
							<Fade bottom delay={200}>
								<div className="mr-3 w-full sm:w-1/3">
									<img src="/images/digital-solutions/thematic-elibrary.png" alt="thematic elibrary" />
									<P shade="darker" size="md" className="text-center">
										Thematic eLibrary
									</P>
								</div>
							</Fade>
							<Fade bottom delay={400}>
								<div className="mr-3 w-full sm:w-1/3">
									<img src="/images/digital-solutions/recorded-lessons.png" alt="prerecorded lessons" />
									<P shade="darker" size="md" className="text-center">
										Video Supports
									</P>
								</div>
							</Fade>
						</div>
					</div>
				</div>
				<P size="sm" shade="light" className="mt-1 text-center">
					Requires a Connect Subscription
				</P>
			</Col>
			<Col className="flex justify-center">
				<Link to="/arc-core">
					<Button label="Learn More About ARC Core" size="lg" />
				</Link>
			</Col>
		</React.Fragment>
	)
}
